"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var router_1 = require("@/router");

var vue_1 = require("vue");

var common_ui_1 = require("../../../common-ui");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      isDev: common_ui_1.isDev,
      isStaging: common_ui_1.isStaging,
      routes: router_1.routes
    };
  },
  computed: {
    routeName: function routeName() {
      return this.$route.path;
    }
  }
});